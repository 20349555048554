import axiosInstance, { addPlainTextHeader } from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {

        }
    },

    actions: {
        fetchTasksForClientAndProject({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/tasks`, rootGetters["authModule/getUrlConfig"])
            .then(result => result?.data)
        },
        fetchTaskBasic({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/basic`, rootGetters["authModule/getUrlConfig"])
            .then(result => result?.data)
        },
        fetchTaskGeneral({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/general`, rootGetters["authModule/getUrlConfig"])
              .then(result => result?.data)
        },
        deleteTask({ rootGetters }, data) {
            return axiosInstance.delete(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.taskId}`, rootGetters["authModule/getUrlConfig"])
            .then(result => result?.data)
        },
        createTask({ rootGetters }, taskCreateDTO) {
            return axiosInstance.post(`/clients/${taskCreateDTO.clientId}/projects/${taskCreateDTO.projectId}/tasks`, taskCreateDTO, rootGetters["authModule/getUrlConfig"])
        },
        getFunctionalIdSuggestions({ rootGetters }, data) {
            const url = `/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.seed}/gen-functional-id`;

            // Conditionally add the query parameter if it exists in `data`
            const config = {
                ...rootGetters["authModule/getUrlConfig"],
                params: data.existingId ? { existingId: data.existingId } : {}
            };

            return axiosInstance.get(url, config)
                .then(
                    result => {
                        return result?.data
                    }
                )
        },
        fetchTaskName({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/task-name`, rootGetters["authModule/getUrlConfig"])
                .then((result) => result?.data)
        },
        updateTaskName({ rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/task-name`, data.taskName, addPlainTextHeader(rootGetters["authModule/getUrlConfig"]))
        },
        updateTaskStatus({ rootGetters }, data) {
          return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/task-status`, data.taskStatusDTO, rootGetters["authModule/getUrlConfig"])
        },
        fetchTaskStatus({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/task-status`, rootGetters["authModule/getUrlConfig"])
                .then((result) => result?.data)
        },
        updateFunctionalId({ rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/functional-id`, data.taskId, addPlainTextHeader(rootGetters["authModule/getUrlConfig"]))
        },
        fetchTimeSlots({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/time-slots`, rootGetters["authModule/getUrlConfig"])
                .then((result) => result?.data)
        },
        updateTimeSlots({ rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/time-slots`, data.payload, rootGetters["authModule/getUrlConfig"])
                .then((result) => result?.data)
        },

    }
}
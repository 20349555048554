import store from "@/store";
import router from "@/router";

export function authenticatedUser() {
    return store.state.authModule.user;
}

export function authenticationIsRequired(route) {
    // authentication is required for every route that has a role defined (meta)
    return !!router.resolve(route).route.meta.role
}

export function hasAccessToRoute(route) {
    // readout role from router definition
    const allowedRoles = router.resolve(route).route.meta.role
    // when no roles are defined, no authorization is being applied on the route
    return !allowedRoles ? true : (store.state.authModule.user?.roles?.some(role => allowedRoles.includes(role)) || false)
}

export function organizationHasParameterTeamsByRoute(route){
    return router.resolve(route).route.meta.parameter?.includes("TEAMS") ? store.state.parameterModule.parameters?.find((parameter) => parameter.name === 'EnableTeamsOption')?.value.toString() === 'true' : true
}

export function isAdmin() {
    return store.getters['authModule/isAdmin']
}

export function isLeader() {
    return store.getters['authModule/isLeader']
}

export function isAuthenticatedUser(user) {
    return user ? user.email === authenticatedUser()?.email : false;
}

export async function hasActiveSubscription() {
    let active = false
    if (authenticatedUser()) {
        // We want to fetch the subscription status when
        // - We haven't fetched it yet
        // - The org hasn't got any subDaysLeft, meaning that they could extend their subscription at any moment
        // - The subscription is a trial, which could be upgraded at any moment
        // Doing this fetch so often ensures that all users of an org will be able to use the application when a subscription is activated
        if (!store.state.organizationModule.subDaysLeft || store.state.organizationModule.subDaysLeft <= 0 
            || store.state.organizationModule.trial == null || store.state.organizationModule.trial) {
            return await store.dispatch('organizationModule/fetchSubscriptionStatus').then(data => { return data.subDaysLeft != null && data.subDaysLeft > 0})
        } else {
            active = store.state.organizationModule.subDaysLeft != null && store.state.organizationModule.subDaysLeft > 0;
        }
    }
    return active
}

export function organizationHasModuleByRoute(route){
    // readout module from router definition
    const allowedModule = router.resolve(route).route.meta.module
    // when no modules are defined, no authorization is being applied on the route
    return !allowedModule ? true : store.getters["organizationModule/organizationHasModule"](allowedModule);
}

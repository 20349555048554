import axiosInstance, {addPlainTextHeader} from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {
            clients: [],
            client: null,
        }
    },
    mutations: {
        setClients(state, clients) {
            state.clients = clients
        },
        setClient(state, client) {
            state.client = client
        },
    },
    actions: {
        fetchActiveClientsForUser({commit, rootGetters}, userId) {
            return axiosInstance.get('/clients/active/user?userId=' + userId, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setClients', result?.data) 
                    return result?.data
                })
        },
        fetchClientsThatHaveProjectsForOrganization({commit, rootGetters}) {
            return axiosInstance.get('/clients/organization?shouldHaveProjects=true', rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setClients', result?.data)
                    return result?.data
                })
        },
        fetchClient({commit, rootGetters}, clientId) {
            return axiosInstance.get('/clients/' + clientId, rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setClient', result?.data)
                    return result?.data
                })
        },
        createClient({dispatch, rootGetters}, clientDto) {
            return axiosInstance.post('/clients', clientDto, rootGetters["authModule/getUrlConfig"])
                .then((result) => {
                    dispatch('fetchNotArchivedClients')
                    return result?.data
                })
        },
        fetchClientName({rootGetters}, clientId) {
            return axiosInstance.get(`/clients/${clientId}/name`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        fetchContactInformation({rootGetters}, data) {
            return axiosInstance.get(`/clients/${data.clientId}/contact-information`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        updateContactInformation({rootGetters}, data) {
            return axiosInstance.put(`/clients/${data.clientId}/contact-information`, data.contactInformation, rootGetters["authModule/getUrlConfig"])
        },
        fetchNotArchivedClients({commit, rootGetters}) {
            return axiosInstance.get('/clients/notArchived', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setClients', result?.data)
                    return result?.data
                })
        },
        updateClient({dispatch, rootGetters}, client) {
            axiosInstance.put('/clients/', client, rootGetters["authModule/getUrlConfig"])
                .then(() => dispatch('fetchNotArchivedClients'))
        },
        updateClientName({rootGetters}, data) {
            return axiosInstance.put(`/clients/${data.clientId}/client-name`, data.clientName, addPlainTextHeader(rootGetters["authModule/getUrlConfig"]))
        },
        deleteClient({dispatch, rootGetters}, clientId) {
            return axiosInstance.delete('/clients/' + clientId, rootGetters["authModule/getUrlConfig"])
                .then(() => dispatch('fetchNotArchivedClients'))
        },
        toggleClientActive({commit, rootGetters}, clientId) {
            return axiosInstance.put(`/clients/${clientId}/active`, null, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setClient', result?.data)
                })
        },
        fetchAllClients({commit, rootGetters}) {
            return axiosInstance.get('/clients/organization', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setClients', result?.data)
                    return result?.data
                })
        },
        fetchBasicClientDTO({rootGetters}, clientId) {
            return axiosInstance.get(`/clients/${clientId}/basic`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        fetchClientInformation({rootGetters}, data) {
            return axiosInstance.get(`/clients/${data.clientId}/information`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        updateClientInformation({rootGetters}, data) {
            return axiosInstance.put(`/clients/${data.clientId}/information`, data.clientInformation, rootGetters["authModule/getUrlConfig"])
        },
        fetchBasicNotArchivedProjectsForClient({rootGetters}, clientId) {
            return axiosInstance.get(`/clients/${clientId}/projects/basic/not-archived`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        }
    },
    getters: {
        getClientNames(state) {
            let clientNames = []
            for (let clientKey in state.clients) {
                clientNames.push(state.clients[clientKey].clientName)
            }
            return clientNames
        },
    },
}
import axiosInstance from "@/store/axios.instance";

export default {
    namespaced: true,
    actions: {
        async getHoursUsers({rootGetters}, exportRequest) {
            return axiosInstance.post('report/user/',exportRequest,
                rootGetters["authModule/getUrlConfig"])
        },
        async getHoursClients({rootGetters}, exportRequest) {
            return axiosInstance.post('report/client/',exportRequest,
                rootGetters["authModule/getUrlConfig"])
        },
        async getRegistrationsReport({rootGetters}, exportRequest) {
            return axiosInstance.post('report/registration/', exportRequest, rootGetters["authModule/getUrlConfig"])
        },
    }
}
import axios from "axios";
import store from "@/store/index";
import {exception} from "vue-gtag";
import {analyticsEvent, EventCategory} from "@/shared/utils/gtagUtils";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: 15000,
});

// Record request start time
instance.interceptors.request.use(config => {
    config.metadata = {startTime: new Date()}
    return config;
}, function (error) {
    logError(error)
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    logAPIRequestDuration(response);
    return response;
}, async (error) => {
    logAPIRequestDuration(error);
    logError(error)
    await store.dispatch('setError', error.response?.data)
    return Promise.reject(error)
})

instance.interceptors.response.use(response => {
    return response
  }, error => {
    if (error?.response?.status === 401 && (error?.response?.data == null || error?.response?.data == "Er ging iets mis!")) {
        store.dispatch("setError", "Het lijkt erop dat u geen toegang hebt tot deze data. Probeer opnieuw aan te melden.")
    }
    return Promise.reject(error)
})

function logAPIRequestDuration(response) {
    response.config.metadata.endTime = new Date();
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime

    analyticsEvent('api_load', EventCategory.LOAD, {
        'url': response.config.url,
        'duration': response.duration
    })
}

function logError(error) {
    exception({
        description: error.response?.data,
        fatal: false,
    })
}

export function addPlainTextHeader(config) {
    let newConfig = JSON.parse(JSON.stringify(config))
    newConfig.headers['Content-Type'] = 'text/plain'
    return newConfig
}

export function addMultiPartFormDataHeader(config) {
    let newConfig = JSON.parse(JSON.stringify(config))
    newConfig.headers['Content-Type'] = 'multipart/form-data'
    return newConfig
}

export default instance;
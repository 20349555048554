import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import store from '@/store'
import routeNames from "@/router/RouteNames";
import {
    authenticatedUser,
    authenticationIsRequired,
    hasAccessToRoute,
    hasActiveSubscription, organizationHasModuleByRoute, organizationHasParameterTeamsByRoute
} from "@/shared/utils/authenticationUtils";
import { asyncRouteActionIsEnabled } from "@/shared/utils/parameterUtils";
import { routeNameMatches } from "@/shared/utils/routeUtils";
import Parameters from '../shared/constants/Parameters';
import ModuleType from "@/shared/enums/moduleType";

Vue.use(VueRouter)
Vue.use(VueMeta)


const routes = process.env.VUE_APP_MAINTENANCE_MODE ?
    [
        {
            path: '*',
            name: routeNames.MAINTENANCE_MODE,
            component: () => import('@/views/Maintenance'),
        }
    ] :
    [
        {
            path: '/',
            name: routeNames.ROOT,
            redirect: { name: routeNames.CALENDAR },
            component: () => import('@/views/Home'),
            children: [
                {
                    path: '/calendar/:date?',
                    name: routeNames.CALENDAR,
                    component: () => import('@/views/BuildBaseCalendar'),
                    meta: {
                        role: ['USER', 'ADMIN', 'LEADER'],
                    }
                },
                {
                    path: '/calendar/detail/:date',
                    name: routeNames.DAY,
                    component: () => import('@/views/DayOverview.vue'),
                    props: route => ({ ...route.params, returnRoute: { name: routeNames.CALENDAR_DETAIL_USER_EDIT_HOURS } }),
                    meta: {
                        role: ['USER', 'ADMIN', 'LEADER'],
                        module: [ModuleType.TIME_REGISTRATION]
                    }
                },
                {
                    path: '/calendar/detail/:date/location/:clockingId',
                    name: routeNames.CALENDAR_DETAIL,
                    component: () => import('@/views/calendar/ClockingDetailView.vue'),
                    props: route => ({ ...route.params, returnRoute: { name: routeNames.DAY } }),
                    meta: {
                        role: ['USER', 'ADMIN', 'LEADER'],
                        module: [ModuleType.TIME_REGISTRATION, ModuleType.EMPLOYEE_MANAGEMENT]        //TODO add location module when that gets created
                    }
                },
                {
                    path: '/calendar/detail/:date/edit/absence/:clockingId?',
                    name: routeNames.CALENDAR_DETAIL_EDIT_ABSENCE,
                    component: () => import('@/views/calendar/forms/CalendarDetailRegisterAbsence'),
                    props: true,
                    meta: {
                        role: ['USER', 'ADMIN', 'LEADER'],
                        module: [ModuleType.TIME_REGISTRATION]
                    }
                },
                {
                    path: '/calendar/teams/detail/:date/edit/absence/:clockingId?',
                    name: routeNames.CALENDAR_TEAM_DETAIL_USER_EDIT_ABSENCE,
                    component: () => import('@/views/calendar/forms/CalendarDetailRegisterAbsence'),
                    props: route => ({ ...route.params, returnRoute: { name: routeNames.TEAM_MODIFY_USER_HOURS_DAY } }),
                    meta: {
                        role: ['LEADER'],
                        module: [ModuleType.TIME_REGISTRATION],
                        parameter: ['TEAMS']
                    }
                },
                {
                    path: '/calendar/detail/:date/edit/hours/:clockingId?',
                    name: routeNames.CALENDAR_DETAIL_EDIT_HOURS,
                    component: () => import('@/views/calendar/forms/AddHoursView'),
                    props: true,
                    meta: {
                        role: ['USER', 'ADMIN', 'LEADER'],
                        module: [ModuleType.TIME_REGISTRATION]
                    }
                },
                {
                    path: '/calendar/teams/detail/:date/edit/hours/:clockingId?',
                    name: routeNames.CALENDAR_TEAM_DETAIL_USER_EDIT_HOURS,
                    component: () => import('@/views/calendar/forms/AddHoursView'),
                    props: route => ({ ...route.params, returnRoute: { name: routeNames.TEAM_MODIFY_USER_HOURS_DAY } }),
                    meta: {
                        role: ['LEADER'],
                        module: [ModuleType.TIME_REGISTRATION],
                        parameter: ['TEAMS']
                    }
                },
                {
                    path: '/pricking',
                    name: routeNames.PRICKING,
                    component: () => import('@/views/PrickingView.vue'),
                    meta: {
                        role: ['USER', 'ADMIN', 'LEADER'],
                        module: [ModuleType.TIME_REGISTRATION]
                    }
                },
                {
                    path: '/clients',
                    name: routeNames.CLIENT_OVERVIEW,
                    component: () => import('@/views/ClientOverview.vue'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    }
                },
                {
                    path: '/clients/create',
                    name: routeNames.CLIENT_CREATE,
                    component: () => import('@/views/client/forms/ClientCreate.vue'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    }
                },
                {
                    path: '/clients/:clientId',
                    name: routeNames.CLIENT_DETAIL,
                    component: () => import('@/views/client/ClientDetail'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    }
                },
                {
                    path: '/clients/:clientId/edit/client-name',
                    name: routeNames.CLIENT_DETAIL_EDIT_CLIENT_NAME,
                    component: () => import('@/views/client/forms/ClientDetailsEditClientName'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    }
                },
                {
                    path: '/clients/:clientId/edit/client-information',
                    name: routeNames.CLIENT_DETAIL_EDIT_CLIENT_INFORMATION,
                    component: () => import('@/views/client/forms/ClientDetailEditClientInformation'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    }
                },
                {
                    path: '/clients/:clientId/edit/contact-information',
                    name: routeNames.CLIENT_DETAIL_EDIT_CONTACT_INFORMATION,
                    component: () => import('@/views/client/forms/ClientDetailEditContactInformation'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    },
                },
                {
                    path: '/projects/create/:clientId?',
                    name: routeNames.PROJECT_CREATE,
                    component: () => import('@/views/project/forms/ProjectCreate.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    }
                },
                {
                    path: '/projects',
                    name: routeNames.PROJECT_OVERVIEW,
                    component: () => import('@/views/ProjectOverview.vue'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    }
                },
                {
                    path: '/clients/:clientId/projects/:projectId',
                    name: routeNames.PROJECT_DETAIL,
                    component: () => import('@/views/project/ProjectDetail.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    },
                },
                {
                    path: '/clients/:clientId/projects/:projectId/edit/project-name',
                    name: routeNames.PROJECT_DETAIL_EDIT_PROJECT_NAME,
                    component: () => import('@/views/project/forms/ProjectDetailEditProjectName.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    },
                },
                {
                    path: '/clients/:clientId/projects/:projectId/edit/project-information',
                    name: routeNames.PROJECT_DETAIL_EDIT_PROJECT_INFORMATION,
                    component: () => import('@/views/project/forms/ProjectDetailEditProjectInformation.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    },
                },
                {
                    path: '/clients/:clientId/projects/:projectId/edit/project-klant',
                    name: routeNames.PROJECT_DETAIL_EDIT_PROJECT_CLIENT,
                    component: () => import('@/views/project/forms/ProjectDetailsEditClient'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    },
                },
                {
                    path: '/clients/:clientId/projects/:projectId/edit/contact-information',
                    name: routeNames.PROJECT_DETAIL_EDIT_CONTACT_INFORMATION,
                    component: () => import('@/views/project/forms/ProjectDetailEditContactInformation.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    },
                },
                {
                    path: '/clients/:clientId/projects/:projectId/edit/images',
                    name: routeNames.PROJECT_DETAIL_EDIT_IMAGES,
                    component: () => import('@/views/project/forms/ProjectDetailEditImages.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.CLIENT_PROJECTMANAGEMENT]
                    },
                },
                {
                    path: '/hours',
                    name: routeNames.HOUR_CONSULTATION_OVERVIEW,
                    component: () => import('@/views/HourConsultationOverview.vue'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.TIME_REGISTRATION]
                    }
                },
                {
                    path: '/teams/create',
                    name: routeNames.TEAM_CREATE,
                    component: () => import('@/views/team/forms/TeamCreate.vue'),
                    meta: {
                        role: ['ADMIN'],
                    }
                },
                {
                    path: '/teams',
                    name: routeNames.TEAM_OVERVIEW,
                    component: () => import('@/views/TeamOverview'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT],
                        paramsOption: Parameters.EnableTeamsOption
                    }
                },
                {
                    path: '/teams/:teamId',
                    name: routeNames.TEAM_DETAIL,
                    component: () => import('@/components/teamoverview/TeamDetailView'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT],
                        paramsOption: Parameters.EnableTeamsOption
                    }
                },
                // TODO: BB-1248: replace TEAM-DETAIL component with component from TEAM_DETAIL_TEMP and delete TEMP
                {
                    path: '/teams/temp/:teamId',
                    name: routeNames.TEAM_DETAIL_TEMP,
                    component: () => import('@/components/teamoverview/MemberOverview'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT],
                        paramsOption: Parameters.EnableTeamsOption
                    }
                },
                {
                    path: '/teams/:teamId/add/project',
                    name: routeNames.TEAM_DETAIL_ADD_PROJECT,
                    component: () => import('@/views/team/forms/AddTeamProject'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT],
                        paramsOption: Parameters.EnableTeamsOption
                    }
                },
                {
                    path: '/teams/:teamId/add/member',
                    name: routeNames.TEAM_DETAIL_ADD_MEMBER,
                    component: () => import('@/views/team/forms/AddTeamMember'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT],
                        paramsOption: Parameters.EnableTeamsOption
                    }
                },
                {
                    path: '/teams/:teamId/add/leader',
                    name: routeNames.TEAM_DETAIL_ADD_LEADER,
                    component: () => import('@/views/team/forms/AddTeamLeader'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT],
                        paramsOption: Parameters.EnableTeamsOption
                    }
                },
                {

                    path: '/teams/:teamId/edit/team-name',
                    name: routeNames.TEAM_DETAIL_EDIT_TEAM_NAME,
                    component: () => import('@/views/team/forms/TeamDetailEditProjectName'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT],
                        paramsOption: Parameters.EnableTeamsOption
                    }
                },
                {
                    path: '/teams/:teamId/clients',
                    name: routeNames.TEAM_CLIENT_OVERVIEW,
                    component: () => import('@/views/TeamClientOverview'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT],
                        paramsOption: Parameters.EnableTeamsOption
                    }
                },
                {
                    path: '/teams/:teamId/clients/:clientId/',
                    name: routeNames.TEAM_PROJECT_OVERVIEW,
                    component: () => import('@/views/TeamProjectOverview'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT],
                        paramsOption: Parameters.EnableTeamsOption
                    }
                },
                {
                    path: '/users',
                    name: routeNames.USER_OVERVIEW,
                    component: () => import('@/views/user/UserOverview.vue'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT]
                    }
                },
                {
                    path: '/users/teams',
                    name: routeNames.USER_TEAM_OVERVIEW,
                    component: () => import('@/components/teamoverview/UserTeamOverview.vue'),
                    meta: {
                        role: ['LEADER'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT],
                        parameter: ['TEAMS']
                    }
                },
                {
                    path: '/users/create',
                    name: routeNames.USER_CREATE,
                    component: () => import('@/views/user/UserCreate.vue'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT]
                    }
                },
                {
                    path: '/users/:userId',
                    name: routeNames.USER_DETAIL,
                    component: () => import('@/views/user/UserDetail.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT]
                    }
                },
                {
                    path: '/users/:userId/edit/avatar',
                    name: routeNames.USER_DETAIL_EDIT_AVATAR,
                    component: () => import('@/views/user/forms/UserDetailEditAvatars.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT]
                    }
                },
                {
                    path: '/users/:userId/edit/name',
                    name: routeNames.USER_DETAIL_EDIT_NAME,
                    component: () => import('@/views/user/forms/UserDetailHeaderEditName.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT]
                    }
                },
                {
                    path: '/users/:userId/edit/statute',
                    name: routeNames.USER_DETAIL_EDIT_STATUTE,
                    component: () => import('@/views/user/forms/UserDetailHeaderEditStatute.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT]
                    }
                },
                {
                    path: '/users/:userId/edit/personal-information',
                    name: routeNames.USER_DETAIL_EDIT_PERSONAL_INFORMATION,
                    component: () => import('@/views/user/forms/UserDetailEditPersonalInformation.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT]
                    }
                },
                {
                    path: '/users/:userId/edit/contact-information',
                    name: routeNames.USER_DETAIL_EDIT_CONTACT_INFORMATION,
                    component: () => import('@/views/user/forms/UserDetailEditContactInformation.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT]
                    }
                },
                {
                    path: '/users/:userId/edit/jobtitle',
                    name: routeNames.USER_DETAIL_EDIT_JOB_TITLE,
                    component: () => import('@/views/user/forms/UserDetailHeaderEditJobTitle.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT]
                    }
                },
                {
                    path: '/users/:userId/edit/work-schedule',
                    name: routeNames.USER_DETAIL_EDIT_WORK_SCHEDULE,
                    component: () => import('@/views/user/forms/UserDetailEditWorkSchedule.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT]
                    }
                },
                {
                    path: '/users/:userId/edit/role',
                    name: routeNames.USER_DETAIL_EDIT_ROLE,
                    component: () => import('@/views/user/forms/UserDetailEditRole.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.EMPLOYEE_MANAGEMENT]
                    }
                },
                {
                    path: '/users/:userId/calendar/:date',
                    name: routeNames.MODIFY_USER_HOURS,
                    props: true,
                    component: () => import('@/components/registerHours/ModifyUserHours'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.TIME_REGISTRATION]
                    }
                },
                {
                    path: '/users/teams/:userId/calendar/:date',
                    name: routeNames.TEAM_MODIFY_USER_HOURS,
                    props: route => ({
                        ...route.params, linkText: "team leden", returnRoute: { name: routeNames.USER_TEAM_OVERVIEW },
                        detailRoute: { name: routeNames.TEAM_MODIFY_USER_HOURS_DAY }, navRoute: { name: routeNames.TEAM_MODIFY_USER_HOURS }
                    }),
                    component: () => import('@/components/registerHours/ModifyUserHours'),
                    meta: {
                        role: ['LEADER'],
                        module: [ModuleType.TIME_REGISTRATION],
                        parameter: ['TEAMS']
                    }
                },
                {
                    path: '/users/:userId/calendar/detail/:date',
                    name: routeNames.MODIFY_USER_HOURS_DAY,
                    props: true,
                    component: () => import('@/components/registerHours/UserDayOverview'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.TIME_REGISTRATION]
                    }
                },
                {
                    path: '/users/:userId/calendar/detail/:date/location/:clockingId',
                    name: routeNames.USER_CALENDAR_DETAIL,
                    component: () => import('@/views/calendar/UserClockingDetailView.vue'),
                    props: route => ({ ...route.params, returnRoute: { name: routeNames.MODIFY_USER_HOURS_DAY } }),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.TIME_REGISTRATION, ModuleType.EMPLOYEE_MANAGEMENT]        //TODO add location module when that gets created
                    }
                },
                {
                    path: '/users/teams/:userId/calendar/detail/:date',
                    name: routeNames.TEAM_MODIFY_USER_HOURS_DAY,
                    props: route => ({
                        ...route.params, returnRoute: { name: routeNames.TEAM_MODIFY_USER_HOURS },
                        editHoursRoute: { name: routeNames.TEAM_CALENDAR_DETAIL_USER_EDIT_HOURS },
                        editAbsenceRoute: { name: routeNames.TEAM_CALENDAR_DETAIL_USER_EDIT_ABSENCE },
                        navRoute: { name: routeNames.TEAM_MODIFY_USER_HOURS_DAY }
                    }),
                    component: () => import('@/components/registerHours/UserDayOverview'),
                    meta: {
                        role: ['LEADER'],
                        module: [ModuleType.TIME_REGISTRATION],
                        parameter: ['TEAMS']
                    }
                },
                {
                    path: '/users/:userId/calendar/detail/:date/edit/absence/:clockingId?',
                    name: routeNames.CALENDAR_DETAIL_USER_EDIT_ABSENCE,
                    component: () => import('@/views/calendar/forms/CalendarDetailRegisterAbsence'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.TIME_REGISTRATION]
                    }
                },
                {
                    path: '/users/teams/:userId/calendar/detail/:date/edit/absence/:clockingId?',
                    name: routeNames.TEAM_CALENDAR_DETAIL_USER_EDIT_ABSENCE,
                    component: () => import('@/views/calendar/forms/CalendarDetailRegisterAbsence'),
                    props: route => ({ ...route.params, returnRoute: { name: routeNames.TEAM_MODIFY_USER_HOURS_DAY } }),
                    meta: {
                        role: ['LEADER'],
                        module: [ModuleType.TIME_REGISTRATION],
                        parameter: ['TEAMS']
                    }
                },
                {
                    path: '/users/teams/:userId/calendar/detail/:date/edit/hours/:clockingId?',
                    name: routeNames.TEAM_CALENDAR_DETAIL_USER_EDIT_HOURS,
                    component: () => import('@/views/calendar/forms/AddHoursView'),
                    props: route => ({ ...route.params, returnRoute: { name: routeNames.TEAM_MODIFY_USER_HOURS_DAY } }),
                    meta: {
                        role: ['LEADER'],
                        module: [ModuleType.TIME_REGISTRATION],
                        parameter: ['TEAMS']
                    }
                },
                {
                    path: '/users/:userId/calendar/detail/:date/edit/hours/:clockingId?',
                    name: routeNames.CALENDAR_DETAIL_USER_EDIT_HOURS,
                    component: () => import('@/views/calendar/forms/AddHoursView'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.TIME_REGISTRATION]
                    }
                },
                {
                    path: '/settings',
                    component: () => import('@/views/settings/Settings.vue'),
                    name: routeNames.SETTINGS,
                    redirect: { name: routeNames.SETTINGS_PARAMETERS },
                    meta: {
                        role: ['ADMIN'],
                    },
                    children: [
                        {
                            name: routeNames.SETTINGS_PARAMETERS,
                            path: 'parameters',
                            component: () => import('@/components/settings/parameter/Parameters.vue'),
                            meta: {
                                role: ['ADMIN'],
                                icon: 'mdi-wrench',
                                activating_routes: []
                            }
                        },
                        {
                            name: routeNames.SETTINGS_ORGANIZATION_PARENT,
                            path: 'organization/:organizationId',
                            component: () => import('@/components/settings/Organization.vue'),
                            redirect: route => {
                                return {
                                    name: routeNames.ORGANIZATION_DETAIL, params: { ...route.params, organizationId: route.params.organizationId ? route.params.organizationId : authenticatedUser()?.organizationId }
                                }
                            },
                            props: route => {
                                return { params: { ...route.params, organizationId: route.params.organizationId ? route.params.organizationId : authenticatedUser()?.organizationId } }
                            },
                            meta: {
                                role: ['ADMIN'],
                                icon: 'mdi-briefcase-outline',
                                activating_routes: []
                            },
                            children: [
                                {
                                    name: routeNames.ORGANIZATION_DETAIL,
                                    path: '',
                                    component: () => import('@/views/settings/OrganizationDetail.vue'),
                                    props: true,
                                    meta: {
                                        role: ['ADMIN'],
                                    }
                                },
                                {
                                    path: 'edit/logo',
                                    name: routeNames.ORGANIZATION_DETAIL_EDIT_LOGO,
                                    component: () => import('@/views/organization/forms/OrganizationDetailEditLogo.vue'),
                                    props: true,
                                    meta: {
                                        role: ['ADMIN'],
                                    }
                                },
                                {
                                    path: 'edit/organization-name',
                                    name: routeNames.ORGANIZATION_DETAIL_EDIT_ORGANIZATION_NAME,
                                    component: () => import('@/views/organization/forms/OrganizationDetailsEditOrganizationName'),
                                    props: true,
                                    meta: {
                                        role: ['ADMIN'],
                                    }
                                },
                                {
                                    path: 'edit/organization-information',
                                    name: routeNames.ORGANIZATION_DETAIL_EDIT_ORGANIZATION_INFORMATION,
                                    component: () => import('@/views/organization/forms/OrganizationDetailEditOrganizationInformation'),
                                    props: true,
                                    meta: {
                                        role: ['ADMIN'],
                                    }
                                },
                            ],
                        },
                        {
                            name: routeNames.SETTINGS_LICENSE,
                            path: 'license',
                            component: () => import('@/components/settings/SubscriptionInfo.vue'),
                            meta: {
                                role: ['ADMIN'],
                                icon: 'mdi-credit-card-outline',
                                activating_routes: []
                            }
                        },
                        {
                            name: routeNames.SETTINGS_WORK_SCHEDULES_PARENT,
                            path: 'work-schedules',
                            component: () => import('@/components/settings/WorkSchedules.vue'),
                            redirect: { name: routeNames.SETTINGS_WORK_SCHEDULES },
                            meta: {
                                role: ['ADMIN'],
                                icon: 'mdi-briefcase-outline',
                                activating_routes: []
                            },
                            children: [
                                {
                                    name: routeNames.SETTINGS_WORK_SCHEDULES,
                                    path: '',
                                    component: () => import('@/components/settings/WorkSchedulesOverview.vue'),
                                    meta: {
                                        role: ['ADMIN'],
                                    }
                                },
                                {
                                    path: ':id',
                                    name: routeNames.WORK_SCHEDULE_DETAILS,
                                    component: () => import('@/views/settings/WorkScheduleDetailsView'),
                                    meta: {
                                        role: ['ADMIN'],
                                    }
                                },
                                {
                                    path: 'create',
                                    name: routeNames.CREATE_WORK_SCHEDULE,
                                    component: () => import('@/views/settings/WorkScheduleDetailsView'),
                                    meta: {
                                        role: ['ADMIN'],
                                    }
                                },
                            ]
                        },
                        {
                            name: routeNames.SETTINGS_LOCATION,
                            path: 'ciao',
                            component: () => import('@/components/settings/location/Location.vue'),
                            meta: {
                                role: ['ADMIN'],
                                module: [ModuleType.LOCATION_REGISTRATION],
                                icon: 'mdi-timer-outline',
                                activating_routes: [],
                            }
                        },
                        {
                            name: routeNames.SETTINGS_INVOICING,
                            path: 'invoicing',
                            component: () => import('@/components/settings/invoicing/Invoicing.vue'),
                            meta: {
                                role: ['ADMIN'],
                                module: [ModuleType.INVOICE],
                                icon: 'mdi-invoice-text-multiple-outline',
                                activating_routes: [],
                            }
                        }
                    ],
                },
                {
                    path: '/invoice',
                    name: routeNames.INVOICE_OVERVIEW,
                    component: () => import('@/views/invoice/InvoiceOverview.vue'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.INVOICE]
                    }
                },
                {
                    path: '/invoices/create',
                    name: routeNames.INVOICE_CREATE,
                    component: () => import('@/views/invoice/InvoiceEdit.vue'),
                    props: route => ({
                        ...route.params,
                        ...route.query,
                        credit: (route.query.credit ? JSON.parse(route.query.credit) : null)
                    }),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.INVOICE]
                    }
                },
                {
                    path: '/invoices/:invoiceId/edit',
                    name: routeNames.INVOICE_EDIT,
                    component: () => import('@/views/invoice/InvoiceEdit.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.INVOICE]
                    }
                },
                {
                    path: '/invoices/:invoiceId',
                    name: routeNames.INVOICE_DETAIL,
                    component: () => import('@/views/invoice/InvoiceDetail.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.INVOICE]
                    }
                },
                {

                    path: '/password-reset',
                    name: routeNames.PASSWORD_RESET,
                    component: () => import('@/components/settings/PasswordReset'),

                    meta: {
                        role: ['USER', 'ADMIN', 'LEADER'],
                    }
                },
                {
                    path: '/users/:userId/password-change',
                    name: routeNames.PASSWORD_CHANGE,
                    props: true,
                    component: () => import('@/views/user/forms/UserEditPassword.vue'),
                    meta: {
                        role: ['ADMIN'],
                    }
                },
                {
                    path: '/planning',
                    name: routeNames.PLANNING,
                    props: true,
                    component: () => import('@/views/planning/PlanningView.vue'),
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.PLANNING]
                    }
                },
                {
                    path: 'clients/:clientId/projects/:projectId/tasks/create',
                    name: routeNames.TASK_CREATE,
                    component: () => import('@/views/planning/forms/TaskCreate.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.PLANNING]
                    }
                },
                {
                    path: 'clients/:clientId/projects/:projectId/tasks/:functionalId',
                    name: routeNames.TASK_DETAIL,
                    component: () => import('@/views/planning/TaskDetail.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.PLANNING]
                    }
                },
                {
                    path: 'clients/:clientId/projects/:projectId/tasks/:functionalId/edit/task-name',
                    name: routeNames.TASK_DETAIL_EDIT_TASK_NAME,
                    component: () => import('@/views/planning/forms/TaskDetailEditTaskName.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.PLANNING]
                    }
                },
                {
                    path: 'clients/:clientId/projects/:projectId/tasks/:functionalId/edit/task-status',
                    name: routeNames.TASK_DETAIL_EDIT_TASK_STATUS,
                    component: () => import('@/views/planning/forms/TaskDetailEditStatus.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.PLANNING]
                    }
                },
                {
                    path: 'clients/:clientId/projects/:projectId/tasks/:functionalId/edit/functional-id',
                    name: routeNames.TASK_DETAIL_EDIT_FUNCTIONAL_ID,
                    component: () => import('@/views/planning/forms/TaskDetailEditFunctionalId.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.PLANNING]
                    }
                },
                {
                    path: 'clients/:clientId/projects/:projectId/tasks/:functionalId/edit/work-schema',
                    name: routeNames.TASK_DETAIL_EDIT_WORK_SCHEMA,
                    component: () => import('@/views/planning/forms/TaskDetailEditWorkSchema.vue'),
                    props: true,
                    meta: {
                        role: ['ADMIN'],
                        module: [ModuleType.PLANNING]
                    }
                },
                {
                    path: '/option-disabled',
                    name: routeNames.OPTION_DISABLED,
                    component: () => import('@/views/OptionDisabled.vue'),
                },
                {
                    path: '/denied',
                    name: routeNames.DENIED,
                    component: () => import('@/views/Denied.vue'),
                },
            ]
        },
        {
            path: '/forgot-password',
            name: routeNames.FORGOT_PASSWORD,
            component: () => import('@/views/ForgotPassword.vue'),
        },
        {
            path: '/password-reset-token',
            name: routeNames.PASSWORD_RESET_TOKEN,
            component: () => import('@/views/ResetPasswordToken.vue'),
        },
        {
            path: '/login',
            name: routeNames.LOGIN,
            component: () => import('@/views/Login.vue'),
        },
        {
            path: '/logout',
            name: routeNames.LOGOUT,
            beforeEnter: (from, to, next) => {
                store.dispatch('logOut')
                next({ name: routeNames.LOGIN })
            },
        },
        {
            path: '/finalize-payment',
            name: routeNames.FINALIZE_PAYMENT,
            component: () => import('@/views/FinalizePaymentView.vue'),
        },
        {
            path: '/payment',
            name: routeNames.PAYMENT,
            component: () => import('@/views/payment/PaymentView.vue')
        },
        {
            path: '/trial',
            name: routeNames.TRIAL,
            component: () => import('@/views/payment/PaymentView.vue')
        },
        {
            path: '/trial-to-paid',
            name: routeNames.TRIAL_TO_PAID,
            component: () => import('@/views/payment/PaymentView.vue'),
            meta: {
                role: ['ADMIN'],
            }
        },
        {
            path: '/EULA',
            name: routeNames.EULA,
            beforeEnter() {
                location.href = 'https://buildbase.eula.strouwi.be/Buildbase-SaaS-Agreement.pdf'
            }
        },
        {
            path: '/contact',
            name: routeNames.CONTACT,
            beforeEnter() {
                location.href = ' https://www.buildbase.be/contact/#contact'
            }
        },
        {
            path: '*',
            redirect: { name: routeNames.ROOT },
        }
    ]

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
    },
})

export default router

router.beforeEach(async (to, from, next) => {
    document.title = to.name + " - " + process.env.VUE_APP_TITLE;

    if (authenticationIsRequired(to) && !authenticatedUser()) {
        if (localStorage.getItem('token')) {
            // authenticate user with token
            // don't use router.push in beforeEach, this causes RedirectErrors.
            // They are non-breaking errors, but it is best-practice to use 'next' for redirect
            // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
            await store.dispatch('authModule/loginWithToken', localStorage.getItem('token'))
                .then(() => next(to))
                .catch(() => next({ name: routeNames.LOGOUT }))
        } else {
            // forward to login, to authenticate the user
            next({ name: routeNames.LOGIN })
        }
        return
    }

    //If we're on the login page, we try to authenticate if there is a token present
    //If the authentication succeeds, we redirect to root
    //If it fails, we logout to remove the token
    if (to.name === routeNames.LOGIN) {
        if (localStorage.getItem('token')) {
            await store.dispatch('authModule/loginWithToken', localStorage.getItem('token'))
                .then(() => next({ name: routeNames.ROOT }))
                .catch(() => next({ name: routeNames.LOGOUT }))
        }
    }

    if (authenticatedUser()) {
        if (!store.getters["organizationModule/getModules"] || store.getters["organizationModule/getModules"].length <= 0) {
            await store.dispatch('organizationModule/fetchModulesFromOrganization')
        }

        if (!organizationHasModuleByRoute(to)) {
            next({ name: routeNames.DENIED })
            return
        }

        if (!hasAccessToRoute(to)) {
            // user doesn't have authorization access to the route
            next({ name: routeNames.DENIED })
            return
            // TODO refactor to hasRequiredModule (sort of same principle of routeActionIsEnabled)
        }
        if (!organizationHasParameterTeamsByRoute(to)) {
            // teams parameter is disabled
            next({ name: routeNames.DENIED })
            return
        }

        const subscriptionActive = await hasActiveSubscription()
        if (!routeNameMatches(to, [routeNames.SETTINGS, routeNames.TRIAL_TO_PAID, routeNames.LOGOUT]) && !subscriptionActive) {
            // if user has an invalid or expired subscription, go to settings page where subscription can be renewed
            // if user is already navigating to the settings page, ignore -> otherwise we are creating an infinite loop
            // important to check the active subscription details before the settings view. To ensure active subscription details is always loaded, also when navigating directly to the settings view
            next({ name: routeNames.SETTINGS })
            return
        }

        if (!await asyncRouteActionIsEnabled(to)) {
            // route action is disabled by organization params
            next({ name: routeNames.OPTION_DISABLED })
            return
        }

        if (to.name === routeNames.LOGIN) {
            // user is already authenticated, forward to root page
            next({ name: routeNames.ROOT })
            return
        }
    }

    next()
})